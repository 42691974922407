import { makeStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import BlurOn from '@material-ui/icons/BlurOn';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ForwardIcon from '@material-ui/icons/Forward';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import Grow from '@material-ui/core/Grow';
import {withRouter} from 'react-router';
import { API, Auth } from 'aws-amplify';
const useStyles = makeStyles((theme) => ({
  accountCircle: {
    fontSize: '30px'
  },
  appBar: {
    flexGrow: 1,
  },
  blurOn: {
    fontSize: '36px',
    marginRight: theme.spacing(1)
  },
  forward: {
    scale: 1.3,
    transform: 'rotate(270deg)',
  },
  searchBar: {
    padding: '2px',
    height: '36px',
    display: 'inline-flex',
    margin: '8px auto',
    maxWidth: 550,
    flexGrow: 1,
  },
  searchBarInput: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  searchBarIcon: {
    width: '36px'
  },
  root: {
    top: '0px',
    position: 'fixed',
    width: '100vw',
    zIndex: 2
    // margin: theme.spacing(2),
  },
  title: {
    marginRight: theme.spacing(6),
  },
  iconDiv: {
    marginLeft: theme.spacing(4),
    whiteSpace: 'nowrap'
  }
}));
function Navbar(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [scrollButtonDisabled, setScrollButtonDisabled] = React.useState(true);
  const [userToSearch, setUserToSearch] = React.useState('');
  const open = Boolean(anchorEl);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  }
  const handleClose = (event) => {
    if(event.target.title === "logout") {
      Auth.signOut().then(() => {
        props.userLoggedOut();
      });
    }
    setAnchorEl(null);
  }
  const handleScrollButton = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  const searchUser = (event) => {
    event.preventDefault();
    API.get("holler-api", `/get-user/${userToSearch}`)
    .then((result) => {
      if(result.Count > 0) {
        props.history.push(`/user/${userToSearch}`);
        props.history.go(0);
      } else {
        alert(`User "${userToSearch}" does not exist`);
      }
    })
  }
  React.useEffect(() => {
     const handleScroll = () => {
        setScrollButtonDisabled(window.pageYOffset === 0);
     };
     window.addEventListener("scroll", handleScroll, { passive: true });
     return () => window.removeEventListener("scroll", handleScroll);
   }, [scrollButtonDisabled]);
  let menuItems = [
    <MenuItem title="login" key="login" onClick={handleClose} component={RouterLink} to='/login'>Log In</MenuItem>,
    <MenuItem title="signup" key="signup" onClick={handleClose} component={RouterLink} to='/signup'>Sign Up</MenuItem>
  ];
  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position='static'>
        <Toolbar style={{minHeight: '56px'}}>
          <BlurOn className={classes.blurOn}/>
          <Typography variant='h6' className={classes.title}>
            <Link color='inherit' component={RouterLink} to='/' underline='none'>Holler</Link>
          </Typography>
          <Paper component="form" elevation={2} className={classes.searchBar}>
              <InputBase
                className={classes.searchBarInput}
                placeholder="Search Users..."
                inputProps={{ 'aria-label': 'search users' }}
                value={userToSearch}
                onChange={(event) => setUserToSearch(event.target.value)}
              />
              <IconButton type="submit" className={classes.searchBarIcon}
              aria-label="search"
              onClick={searchUser}>
                <SearchIcon />
              </IconButton>
          </Paper>
          <div className={classes.iconDiv}>
            <Grow in={scrollButtonDisabled === false}>
              <IconButton
              color='inherit'
              disabled={scrollButtonDisabled}
              onClick={handleScrollButton}
              className={classes.forwardIconButton}>
                <ForwardIcon className={classes.forward} />
              </IconButton>
            </Grow>
            <IconButton
            aria-label='Account options'
            aria-controls='menu-appbar'
            aria-haspopup='true'
            onClick={handleMenu}
            color='inherit'>
              <AccountCircle className={classes.accountCircle} />
            </IconButton>
            <Menu
            id='menu-appbar'
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}>
              {
                props.loggedIn !== true ?
                menuItems :
                <MenuItem title="logout" key="logout" onClick={handleClose}>Log Out</MenuItem>
              }
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
export default withRouter(Navbar);
