import { API, Storage } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import CircularProgress from '@material-ui/core/CircularProgress';
import Comment from './Comment';
import { getDateDifferenceString, extractLinks } from '../libs/utility';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import CardMedia from '@material-ui/core/CardMedia';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import EditIcon from '@material-ui/icons/Edit';
import clsx from 'clsx';
const styles = theme => ({
  avatar: {},
  cardContent: {
    paddingTop: '16px',
    paddingBottom: '0',
    "&:last-child": {
      paddingBottom: '0'
    },
    display: 'inline-block',
    minWidth: '0'
  },
  cardHeader: {
    paddingBottom: '6px'
  },
  contentTypography: {
    paddingLeft: '2px',
    paddingBottom: '16px',
    minWidth: '0'
  },
  expand: {
    padding: '6px',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  delete: {
    padding: '5px'
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  progressRoot: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    margin: '16px'
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    padding: '16px',
  },
  divider: {
    marginLeft: '8px',
    marginRight: '8px'
  },
  commentTextField: {
    marginRight: '8px'
  },
  root: {
    margin: '24px'
  },
  cardActions: {
    display: 'inline-block',
    whiteSpace: 'nowrap'
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: '6px'
  },
  edit: {
    padding: '5px',
  },
  like: {
    marginRight: '2px',
    padding: '5px'
  }
});
class Post extends React.Component {
  // This functions is called 60 times, WTF? (O.o)
  loadImageFromS3 = async (key) => {
    if(key == null || key.length == 0) return;
    this.setState({
      profileImageSource: null
    });
    Storage.get(key, { level: 'public'})
    .then(result => {
      this.setState({
        profileImageSource: result
      });
    }).catch(e => console.log(e));
  }
  constructor(props) {
    super(props);
    this.state = {
      aspectRatio: 0,
      expanded: false,
      comments: null,
      likesCount: null,
      postLiked: false,
      profileImageKey: null,
      profileImageSource: null,
      newCommentContent: '',
      isEditing: false,
      editedContent: ''
    };
  }
  componentDidMount() {
    API.get("holler-api", `/get-post-likes/${this.props.post.postId}`)
    .then(result => {
      let postLiked = false;
      for(let i = 0; i < result.Count; i += 1) {
        if(result.Items[i].username === this.props.currentUser) {
          postLiked = true;
        }
      }
      this.setState({
        postLiked: postLiked,
        likesCount: result.Count
      });
    })
    .catch(err => console.log(err));
    if(this.props.profileImageKey !== this.state.profileImageKey) {
      this.loadImageFromS3(this.props.profileImageKey);
      this.setState({
        profileImageKey: this.props.profileImageKey
      });
    }
  }
  componentDidUpdate() {
    if(this.props.post.imageURL) {
      let image = new Image();
      image.originComment = this;
      image.onload = function() {
        this.originComment.setState({
          aspectRatio: this.height / (this.width + 20) * 100
        });
      };
      image.src = this.props.post.imageURL;
    }
    if(this.props.profileImageKey !== this.state.profileImageKey) {
      this.loadImageFromS3(this.props.profileImageKey);
      this.setState({
        profileImageKey: this.props.profileImageKey
      });
    }
  }
  handleDeleteClick = () => {
    if(window.confirm('Are you sure you want to delete this post?')) {
      this.props.deletePost(this.props.post);
    }
  }
  handleExpandClick = () => {
    this.setState(state => ({expanded: !state.expanded}), () => {
      if(this.state.expanded) {
        this.getComments();
      }
    });
  }
  getComments = () => {
    API.get("holler-api", `/get-comments/${this.props.post.postId}`)
    .then(result => {
      this.setState({
        comments: result.Items,
      });
    })
    .catch(err => console.log(err));
  }
  handlePostLike = () => {
    if(this.state.postLiked === false) {
      API.put("holler-api", "/like-post", {
        body: {
          username: this.props.currentUser,
          postId: this.props.post.postId,
        }
      }).catch(e => console.log(e));
    } else {
      API.del("holler-api", "/unlike-post", {
        body: {
          username: this.props.currentUser,
          postId: this.props.post.postId,
        }
      }).catch(e => console.log(e));
    }
    this.setState({
      likesCount: this.state.postLiked === false ?
      this.state.likesCount + 1 : this.state.likesCount - 1,
      postLiked: !this.state.postLiked
    });
  }
  addComment = async (event) => {
    event.preventDefault();
    const newComment = await API.put("holler-api", "/create-comment", {
      body: {
        username: this.props.currentUser,
        postId: this.props.post.postId,
        content: this.state.newCommentContent
      }
    });
    const comments = [newComment, ...this.state.comments];
    this.setState({
      comments: comments,
      newCommentContent: ''
    });
  }
  deleteComment = async (comment) => {
    if(this.state.currentUsername !== null) {
      API.del("holler-api", "/delete-comment", {
        body: {
          postId: this.props.post.postId,
          commentId: comment.commentId
        }
      });
      let newComments = [];
      for(let i = 0; i < this.state.comments.length; i += 1) {
        if(this.state.comments[i].commentId !== comment.commentId) {
          newComments.push(this.state.comments[i]);
        }
      }
      this.setState({
        comments: newComments
      });
    }
  }
  handleCommentChange = (event) => {
    this.setState({
      newCommentContent: event.target.value
    });
  }
  handleEditClick = () => {
    this.setState({
      isEditing: true,
      editedContent: this.props.post.content
    });
  }
  handleSave = () => {
    const editedContent = this.state.editedContent;
    this.setState({
      isEditing: false,
      editedContent: ''
    });
    this.props.updatePost(this.props.post.postId, editedContent);
  }
  updateComment = (commentId, editedContent) => {
    API.post("holler-api", "/update-comment", {
      body: {
        postId: this.props.post.postId,
        content: editedContent,
        commentId: commentId
      }
    }).catch((e) => console.log(e));
    let newComments = [...this.state.comments];
    for(let i = 0; i < newComments.length; i += 1) {
      if(newComments[i].commentId === commentId) {
        newComments[i].content = editedContent;
      }
    }
    this.setState({
      comments: newComments
    });
  }
  render() {
    const { classes } = this.props;
    let commentsMarkup =
    <div className={classes.progressRoot}>
      <CircularProgress size={25} color="primary" />
    </div>
    if(this.state.comments) {
      let orderedComments = [...this.state.comments];
      orderedComments.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1);
      commentsMarkup = orderedComments.map
      ((comment, index) => {
        const [links, newContent] = extractLinks(comment.content);
        comment.content = newContent;
        for(let i = 0; i < links.length; i += 1) {
          comment.imageURL = links[i][0];
          break;
        }
        return (
          <Comment
          userIsOwner={
          this.props.currentUser !== null &&
          this.props.currentUser === comment.username}
          deleteComment={this.deleteComment}
          comment={comment}
          key={comment.commentId}
          updateComment={this.updateComment} />
        );
      });
    }
    let mediaStyle = {
      margin: '0 10px',
      marginTop: '8px',
      height: 0,
      paddingTop: this.state.aspectRatio.toString() + '%'
    };
    const likeContainerStyle = {
      display: 'inline-flex',
      flexDirection: 'column',
      justifyContent: 'center',
      paddingTop: '5px',
      paddingRight: '7px'
    };
    let dateDifferenceString = getDateDifferenceString(new Date(this.props.post.createdAt));
    if(dateDifferenceString === "0 seconds") {
      dateDifferenceString = "At the moment";
    } else {
      dateDifferenceString += " ago";
    }
    return (
      <Card raised className={classes.root}>
        <div className={classes.headerContainer}>
          <CardHeader className={classes.cardHeader}
          avatar={
          this.state.profileImageSource !== null ?
          <Avatar variant="rounded" className={classes.avatar} src={this.state.profileImageSource}/> :
          <Avatar variant="rounded" className={classes.avatar}>{this.props.username.charAt(0)}</Avatar>
          }
          title={this.props.post.username}
          subheader={dateDifferenceString}/>
          <div style={{display: 'inline-flex', alignItems: 'stretch', height: '34px'}}>
            <div style={likeContainerStyle}>
              <Typography variant="subtitle1" style={{color: 'rgba(0, 0, 0, 0.45)', userSelect: 'none'}}>
                {this.state.likesCount !== null ? this.state.likesCount : 0}
              </Typography>
            </div>
            <IconButton
            disabled={this.props.userIsAuthenticated === false || this.state.likesCount === null}
            style={{color: this.state.postLiked ? "#3f51b5" : "rgba(0, 0, 0, 0.26)"}}
            className={classes.like}
            onClick={this.handlePostLike}>
              <ThumbUpIcon />
            </IconButton>
            <IconButton
            disabled={this.props.userIsOwner === false || this.state.isEditing}
            color="primary"
            className={classes.edit}
            onClick={this.handleEditClick}>
              <EditIcon />
            </IconButton>
            <IconButton
            disabled={this.props.userIsOwner === false}
            color="secondary"
            className={classes.delete}
            onClick={this.handleDeleteClick}>
              <DeleteForeverIcon />
            </IconButton>
          </div>
        </div>
        {
          this.props.post.imageURL ?
          <CardMedia style={mediaStyle}
          image={this.props.post.imageURL}/> : <span></span>
        }
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          { this.state.isEditing ?
            <CardContent style={{flexGrow: '1', paddingRight: '8px'}}>
              <TextField
              rows={2}
              value={this.state.editedContent}
              onChange={(event) => {
                this.setState({
                  editedContent: event.target.value
                });
              }}
              size="small"
              variant="outlined"
              label="New Content"
              multiline
              fullWidth/>
              <Button
              style={{marginTop: '14px'}}
              color="primary"
              variant="contained"
              onClick={this.handleSave}
              fullWidth>
                Save Changes
              </Button>
            </CardContent>
            :
            <CardContent className={classes.cardContent}>
              <Typography variant="body1" component="p" className={classes.contentTypography}>
                {this.props.post.content}
              </Typography>
            </CardContent>
          }
          <CardActions className={classes.cardActions} style={{alignSelf: 'flex-end'}} disableSpacing>
            <IconButton
            disabled={this.state.isEditing}
            className={clsx(classes.expand, {
            [classes.expandOpen]: this.state.expanded})}
            onClick={this.handleExpandClick}
            aria-expanded={this.state.expanded}>
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
        </div>
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          {commentsMarkup}
           <Divider className={classes.divider} />
          <form className={classes.form} noValidate autoComplete="off">
            <TextField className={classes.commentTextField} size="small"
            fullWidth={true} label="Comment" variant="outlined"
            value={this.state.newCommentContent} onChange={this.handleCommentChange}/>
            <Button disabled={this.props.userIsAuthenticated === false}
            color="primary" variant="outlined" onClick={this.addComment}>
              &nbsp;&nbsp;Submit&nbsp;&nbsp;
            </Button>
          </form>
        </Collapse>
      </Card>
    );
  }
}
Post.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(Post);
