import { API, Auth } from 'aws-amplify';
import * as uuid from "uuid";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import BlurOn from '@material-ui/icons/BlurOn';
import CircularProgress from '@material-ui/core/CircularProgress';
const styles = theme => ({
  root: {
    display: 'flex',
    height: '92vh',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  usernameField: {
    marginTop: '25px',
    width: '300px'
  },
  emailField: {
    marginTop: '20px',
    width: '300px'
  },
  passwordField: {
    marginTop: '20px',
    width: '300px'
  },
  submitButton: {
    textAlign: 'center',
    marginTop: '20px',
    width: '300px',
    height: '40px'
  },
  form: {
    width: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '15px'
  },
  blurOn: {
    color: '#3F51B5',
    fontSize: '64px',
    marginBottom: '30px'
  },
  confirmationCodeField: {
    marginTop: '10px',
    width: '300px'
  },
  infoLabel: {
    marginTop: '30px',
  }
});
class Signup extends React.Component {
  constructor() {
    super();
    this.state = {
      id: '',
      email: '',
      username: '',
      password: '',
      loading: false,
      error: '',
      newUser: null,
      confirmationCode: ''
    };
  }
  handleSubmit = async (event) => {
    // We don't want a page refresh and our
    // credentials to be appended to the URL
    event.preventDefault();
    await this.setState({
      loading: true,
      error: ''
    });
    const result = await API.get("holler-api", `/get-user/${this.state.username}`);
    if(result.Count != 0) {
      this.setState({
        loading: false,
        error: `[Signup Error]: Username "${this.state.username}" is in-use`
      });
      return;
    }
    try {
      const id = uuid.v1();
      this.setState({
        id: id,
      });
      Auth.signUp({
        username: id,
        password: this.state.password,
        attributes: {
          email: this.state.email,
        }
      }).then((newUser) => {
        this.setState({
          loading: false,
          newUser: newUser
        });
      })
    } catch (err) {
      this.setState({
        loading: false,
        error: "[Signup Error]: " + err.message
      });
    }
  }
  handleFieldChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }
  handleConfirmation = async (event) => {
    // Perhaps in the future, have the user choose a username here,
    // instead of during signup and have home redirect to confirmation
    // if the user refreshes or leaves the form before finishing!
    event.preventDefault();
    this.setState({
      loading: true
    });
    await Auth.confirmSignUp(this.state.id, this.state.confirmationCode)
    .catch(err => {
      this.setState({
        loading: false,
        error: "[Authentication Error]: " + err.message
      });
    });
    let user = await Auth.signIn(this.state.id, this.state.password);
    await Auth.updateUserAttributes(user, {
      preferred_username: this.state.username
    });
    await API.put("holler-api", "/create-user", {
      body: {
        username: this.state.username
      }
    });
    this.setState({
      loading: false
    });
    this.props.history.push('/');
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <BlurOn className={classes.blurOn}/>
        <Typography variant='h4'>
          Signup to Holler
        </Typography>
          {
            this.state.newUser ?
            <form noValidate className={classes.form}
            onSubmit={this.handleConfirmation}>
              <Typography variant="subtitle2" color="primary" className={classes.infoLabel}>
                Look at your inbox for a confirmation code
              </Typography>
              <TextField
              id="confirmationCode"
              name="confirmationCode"
              type="confirmationCode"
              label="Confirmation Code"
              variant="outlined"
              size="small"
              className={classes.confirmationCodeField}
              value={this.state.confirmationCode}
              onChange={this.handleFieldChange} fullWidth/>
              <Button
              disabled={this.state.confirmationCode.length === 0
              || this.state.loading}
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submitButton}>
                {this.state.loading === false ?
                'Confirm Signup' :
                <CircularProgress color='inherit' size={23}/>}
              </Button>
            </form>
            :
            <form noValidate className={classes.form}
            onSubmit={this.handleSubmit}>
              <TextField
              id="username"
              name="username"
              type="username"
              label="Username"
              variant="outlined"
              size="small"
              className={classes.usernameField}
              value={this.state.username}
              onChange={this.handleFieldChange} fullWidth/>
              <TextField
              id="email"
              name="email"
              type="email"
              label="Email"
              variant="outlined"
              size="small"
              className={classes.emailField}
              value={this.state.email}
              onChange={this.handleFieldChange} fullWidth/>
              <TextField
              id="password"
              name="password"
              type="password"
              label="Password"
              variant="outlined"
              size="small"
              className={classes.passwordField}
              value={this.state.password}
              onChange={this.handleFieldChange} fullWidth/>
              <Button
              disabled={this.state.password.length === 0
              || this.state.email.length === 0
              || this.state.username.length === 0 || this.state.loading}
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submitButton}>
                {this.state.loading === false ?
                'Signup' :
                <CircularProgress color='inherit' size={23}/>}
              </Button>
            </form>
          }
        <Typography variant="subtitle2" color="error" align="center" style={{width: '300px'}}>
          {this.state.error}
        </Typography>
      </div>
    );
  }
}
Signup.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(Signup);
