export function getDateDifferenceString(date, now = null) {
  if(now === null) now = new Date();
  const delta = now - date;
  const seconds = delta / 1000;
  if(Math.floor(seconds) == 0) {
    return "0 seconds";
  }
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;
  const months = days / 30;
  const years = months / 12;
  let value = 0;
  let unit = "";
  if(years > 1) {
    value = Math.floor(years)
    unit = "year"
  } else if(months > 1) {
    value = Math.floor(months)
    unit = "month"
  } else if(days > 1) {
    value = Math.floor(days)
    unit = "day"
  } else if(hours > 1) {
    value = Math.floor(hours)
    unit = "hour"
  } else if(minutes > 1) {
    value = Math.floor(minutes)
    unit = "minute"
  } else if(seconds > 1) {
    value = Math.floor(seconds)
    unit = "second"
  }
  return value.toString() + " " + unit + (value > 1 ? "s" : "");
}
export function extractLinks(text) {
  const urlRegex = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/igm;
  let arr = [...text.matchAll(urlRegex)];
  return [arr, text.replace(urlRegex, '<Link>')];
}
