import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import Home from './containers/Home';
import Login from './containers/Login';
import Signup from './containers/Signup';
import UserHome from './containers/UserHome';
export default function Routes(props) {
  return (
    <Switch>
      <Route exact path="/" component={()=><Home userLoggedIn={props.userLoggedIn}/>}/>
      <Route exact path="/login" component={Login}/>
      <Route exact path="/signup" component={Signup}/>
      <Route path="/user/:username" component={(routeProps)=><UserHome {...routeProps} userLoggedIn={props.userLoggedIn}/>}/>
    </Switch>
  );
};
