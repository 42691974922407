import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import { getDateDifferenceString } from '../libs/utility';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import { API, Storage } from 'aws-amplify';
const styles = theme => ({
  cardHeader: {
    paddingBottom: '4px',
  },
  cardContent: {
    "&:last-child": {
      paddingBottom: '16px'
    },
    display: 'inline-block',
    minWidth: '0'
  },
  contentTypography: {
    minWidth: '0'
  },
  cardActions: {
    display: 'inline-block'
  },
  divider: {
    marginBottom: '4px',
    marginLeft: '8px',
    marginRight: '8px'
  },
  delete: {
    padding: '5px'
  },
  edit: {
    padding: '5px',
  }
});
class Comment extends React.Component {
  loadImageFromS3 = async (key) => {
    if(key == null || key.length == 0) return;
    this.setState({
      profileImageSource: null
    });
    Storage.get(key, { level: 'public'})
    .then(result => {
      this.setState({
        profileImageSource: result
      });
    }).catch(e => console.log(e));
  }
  constructor(props) {
    super(props);
    this.state = {
      aspectRatio: 0,
      newComment: '',
      creationTime: new Date(),
      profileImageSource: null,
      isEditing: false,
      editedContent: ''
    };
  }
  componentDidUpdate() {
    if(this.props.comment.imageURL) {
      let image = new Image();
      image.originComment = this;
      image.onload = function() {
        this.originComment.setState({
          aspectRatio: this.height / this.width * 100
        });
      };
      image.src = this.props.comment.imageURL;
    }
  }
  componentDidMount() {
    API.get("holler-api", `/get-user/${this.props.comment.username}`)
    .then((result) => {
      if(result.Count > 0) {
        if('profileImageKey' in result.Items[0]) {
          this.loadImageFromS3(result.Items[0].profileImageKey);
        }
      }
    });
  }
  handleDeleteClick = () => {
    if(window.confirm('Are you sure you want to delete this comment?')) {
      this.props.deleteComment(this.props.comment);
    }
  }
  handleEditClick = () => {
    this.setState({
      isEditing: true,
      editedContent: this.props.comment.content
    });
  }
  handleSave = () => {
    const editedContent = this.state.editedContent;
    this.setState({
      isEditing: false,
      editedContent: ''
    });
    this.props.updateComment(this.props.comment.commentId, editedContent);
  }
  render() {
    let { classes, comment } = this.props;
    let mediaStyle = {
      marginTop: '8px',
      height: 0,
      paddingTop: this.state.aspectRatio.toString() + '%'
    };
    let dateDifferenceString = getDateDifferenceString(
    new Date(comment.createdAt), this.state.creationTime);
    if(dateDifferenceString === "0 seconds") {
      dateDifferenceString = "At the moment";
    } else {
      dateDifferenceString += " ago";
    }
    return (
      <React.Fragment>
        <Divider className={classes.divider} />
        <CardHeader className={classes.cardHeader}
        avatar={
          this.state.profileImageSource !== null ?
          <Avatar variant="rounded" className={classes.avatar} src={this.state.profileImageSource}/> :
          <Avatar variant="rounded" className={classes.avatar}>{this.props.comment.username.charAt(0)}</Avatar>}
        title={comment.username}
        subheader={dateDifferenceString}/>
        {
          comment.imageURL ?
          <CardMedia style={mediaStyle}
          image={comment.imageURL}/> : <span></span>
        }
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          {
            this.state.isEditing ?
            <CardContent style={{flexGrow: '1', paddingRight: '8px'}}>
              <TextField
              rows={2}
              value={this.state.editedContent}
              onChange={(event) => {
                this.setState({
                  editedContent: event.target.value
                });
              }}
              size="small"
              variant="outlined"
              label="New Content"
              multiline
              fullWidth/>
              <Button
              style={{marginTop: '14px'}}
              color="primary"
              variant="contained"
              onClick={this.handleSave}
              fullWidth>
                Save Changes
              </Button>
            </CardContent>
            :
            <CardContent className={classes.cardContent}>
              <Typography variant="body1" component="p" noWrap className={classes.contentTypography}>
                {comment.content}
              </Typography>
            </CardContent>
          }
          <CardActions className={classes.cardActions} disableSpacing style={{alignSelf: 'flex-end', marginBottom: '8px'}}>
            <IconButton
            disabled={this.props.userIsOwner === false || this.state.isEditing}
            color="primary"
            className={classes.edit}
            onClick={this.handleEditClick}>
              <EditIcon />
            </IconButton>
            <IconButton
            disabled={this.props.userIsOwner === false}
            color="secondary"
            className={classes.delete}
            onClick={this.handleDeleteClick}>
              <DeleteForeverIcon />
            </IconButton>
          </CardActions>
        </div>
      </React.Fragment>
    );
  }
}
Comment.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(Comment);
