import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
const styles = theme => ({
  card: {
    display: 'block',
    margin: '24px',
    padding: '20px'
  },
  field: {
    marginTop: '8px',
    marginBottom: '15px',
  },
  button: {
    display: 'flex',
    marginLeft: 'auto'
  }
});
class PostCreator extends React.Component {
  state = {
    disabled: this.props.username === null,
    content: ''
  }
  handleFieldChange = (event) => {
    this.setState({
      content: event.target.value
    });
  }
  handleSubmit = () => {
    if(this.state.content.length > 0) {
      this.props.addPost({
        username: this.props.username,
        content: this.state.content
      });
      this.setState({
        content: ''
      })
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <Card raised={true} className={classes.card}>
        <Typography variant="body1">
        Let the world know:
        </Typography>
        <TextField
        size="small"
        rows={2}
        className={classes.field}
        multiline
        value={this.state.content}
        onChange={this.handleFieldChange}
        variant="outlined"
        fullWidth/>
        <Button
        color="primary"
        variant="contained"
        disabled={this.state.disabled}
        className={classes.button}
        onClick={this.handleSubmit}
        fullWidth>
          Holler
        </Button>
      </Card>
    );
  }
}
PostCreator.propTypes = {
  classes: PropTypes.object.isRequired,
  username: PropTypes.string.isRequired
};
export default withStyles(styles)(PostCreator);
