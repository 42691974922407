import { API, Auth } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import BlurOn from '@material-ui/icons/BlurOn';
import CircularProgress from '@material-ui/core/CircularProgress';
const styles = theme => ({
  root: {
    display: 'flex',
    height: '92vh',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  identifierField: {
    marginTop: '25px',
    width: '300px'
  },
  passwordField: {
    marginTop: '20px',
    width: '300px'
  },
  submitButton: {
    textAlign: 'center',
    marginTop: '20px',
    width: '300px',
    height: '40px'
  },
  form: {
    width: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '15px'
  },
  blurOn: {
    color: '#3F51B5',
    fontSize: '64px',
    marginBottom: '30px'
  }
});
class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      identifier: '',
      password: '',
      loading: false,
      error: ''
    };
  }
  handleSubmit = async (event) => {
    // We don't want a page refresh and our
    // credentials to be appended to the URL
    event.preventDefault();
    await this.setState({
      loading: true,
      error: ''
    });
    Auth.signIn(this.state.identifier, this.state.password)
    .then(() => {
      this.setState({
        loading: false
      });
      this.props.history.push('/');
    })
    .catch(err => {
      this.setState({
        loading: false,
        error: "[Authentication Error]: " + err.message
      });
    });
  }
  handleFieldChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <BlurOn className={classes.blurOn}/>
        <Typography variant='h4'>
          Login to Holler
        </Typography>
        <form noValidate className={classes.form}
        onSubmit={this.handleSubmit}>
          <TextField
          id="identifier"
          name="identifier"
          type="identifier"
          label="Email / Username"
          variant="outlined"
          size="small"
          className={classes.identifierField}
          value={this.state.identifier}
          onChange={this.handleFieldChange} fullWidth/>
          <TextField
          id="password"
          name="password"
          type="password"
          label="Password"
          variant="outlined"
          size="small"
          className={classes.passwordField}
          value={this.state.password}
          onChange={this.handleFieldChange} fullWidth/>
          <Button
          disabled={this.state.password.length === 0
          || this.state.identifier.length === 0 || this.state.loading}
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submitButton}>
            {this.state.loading === false ?
            'Login' :
            <CircularProgress color='inherit' size={23}/>}
          </Button>
        </form>
        <Typography variant="subtitle2" color="error">
          {this.state.error}
        </Typography>
      </div>
    );
  }
}
Login.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(Login);
