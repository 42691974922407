import './App.css';
import Navbar from "./components/Navbar";
import React from 'react';
import Routes from "./Routes";
class App extends React.Component {
  constructor() {
    super();
    this.state = {
      loggedIn: false
    }
  }
  userLoggedOut = () => {
    if(this.state.loggedIn === true) {
      this.setState({
        loggedIn: false
      });
    }
  }
  userLoggedIn = () => {
    if(this.state.loggedIn === false) {
      this.setState({
        loggedIn: true
      });
    }
  }
  render() {
    return (
      <div className="App">
        <Navbar loggedIn={this.state.loggedIn} userLoggedOut={this.userLoggedOut}/>
        <Routes userLoggedIn={this.userLoggedIn}/>
      </div>
    );
  }
}
export default App;
