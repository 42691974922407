import { API, Auth } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import LoadingSplash from '../components/LoadingSplash';
import Post from '../components/Post';
import PropTypes from 'prop-types';
import React from 'react';
import { extractLinks } from '../libs/utility';
import Profile from '../components/Profile';
import ProfileSplash from '../components/ProfileSplash';
const styles = theme => ({
  root: {
    margin: theme.spacing(2),
    marginTop: '48px'
  },
  grid: {
    margin: '0 auto',
    maxWidth: '1100px'
  },
  gridItem: {
    paddingLeft: '0',
    paddingRight: '0'
  },
});
class UserHome extends React.Component {
  state = {
    profileLoading: true,
    currentUsername: null,
    currentUser: null,
    posts: null,
    user: null
  }
  updatePost = async (postId, newContent) => {
    API.post("holler-api", "/update-post", {
      body: {
        postId: postId,
        content: newContent,
        username: this.state.currentUsername
      }
    }).catch((e) => console.log(e));
    let newPosts = [...this.state.posts];
    for(let i = 0; i < newPosts.length; i += 1) {
      if(newPosts[i].postId === postId) {
        newPosts[i].content = newContent;
      }
    }
    this.setState({
      posts: newPosts
    });
  }
  deletePost = async (post) => {
    if(this.state.currentUsername !== null) {
      API.del("holler-api", "/delete-post", {
        body: {
          username: this.state.currentUsername,
          postId: post.postId
        }
      });
      let newPosts = [];
      for(let i = 0; i < this.state.posts.length; i += 1) {
        if(this.state.posts[i].postId !== post.postId) {
          newPosts.push(this.state.posts[i]);
        }
      }
      this.setState({
        posts: newPosts
      });
    }
  }
  componentDidMount = async() => {
    API.get("holler-api", `/get-user-posts/${this.props.match.params.username}`).then(result => {
      if(result.Count >= 0) {
        this.setState({
          posts: result.Items
        });
      }
    })
    const result = await API.get("holler-api", `/get-user/${this.props.match.params.username}`);
    let newUser = null;
    if(result.Count > 0) {
      newUser = {username: this.props.match.params.username, ...result.Items[0]};
    }
    let myUser = await Auth.currentAuthenticatedUser().catch(err => {});
    if(myUser !== undefined) {
      this.props.userLoggedIn();
      const result = await API.get("holler-api", `/get-user/${myUser.attributes.preferred_username}`);
      if(result.Count > 0) {
        this.setState({
          currentUser: {username: myUser.attributes.preferred_username, ...result.Items[0]},
          currentUsername: myUser.attributes.preferred_username,
        });
      }
      this.setState({
        user: newUser,
        profileLoading: false
      });
    } else {
      this.setState({
        user: newUser,
        profileLoading: false
      });
    }
  }
  render() {
    const { classes } = this.props;
    let recentPostsMarkup = <LoadingSplash />
    if(this.state.posts) {
      let orderedPosts = [...this.state.posts];
      orderedPosts.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1);
      recentPostsMarkup = orderedPosts.map
      ((post) => {
        const [links, newContent] = extractLinks(post.content);
        post.content = newContent;
        if(links[0] !== undefined) {
          post.imageURL = links[0][0];
        }
        post.username = this.props.match.params.username;
        return (
          <Post
          userIsAuthenticated={this.state.currentUsername !== null}
          currentUser={this.state.currentUsername}
          userIsOwner={
          this.state.currentUsername !== null &&
          this.state.currentUsername === post.username}
          username={post.username}
          profileImageKey={this.state.user !== null &&
          'profileImageKey' in this.state.user ? this.state.user.profileImageKey : null}
          post={post}
          key={post.postId}
          updatePost={this.updatePost}
          deletePost={this.deletePost}/>
        )
      })
    }
    return (
      <div className={classes.root}>
        <Grid container direction="row-reverse" className={classes.grid} spacing={2}>
          <Grid item sm={4} xs={12}>
          {
            this.state.user !== null ?
            <Profile user={this.state.user} editable={false}/>
            :
            <ProfileSplash loading={true}/>
          }
          </Grid>
          <Grid item className={classes.gridItem} sm={8} xs={12}>
          {recentPostsMarkup}
          </Grid>
        </Grid>
      </div>
    );
  }
}
UserHome.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(UserHome);
