import * as uuid from 'uuid';
import { API, Storage } from 'aws-amplify';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import TextField from '@material-ui/core/TextField';
import { withRouter } from "react-router-dom";

const styles = theme => ({
  card: {
    position: 'sticky',
    top: '78px',
    margin: '24px',
    marginLeft: '0px',
    [theme.breakpoints.down('xs')]: {
      position: 'static',
      marginLeft: '24px',
      marginBottom: '0px'
    }
  },
  edit: {
    display: 'flex',
    marginLeft: 'auto'
  },
  saveButton: {
    margin: '20px',
    marginTop: '0px',
    width: 'calc(100% - 40px)'
  },
  attributeContainer: {
    padding: '20px',
  },
  usernameText: {
    marginBottom: '10px'
  },
  uploadButton: {
    display: 'flex',
    margin: '15px auto',
  },
  avatar: {
    width: 'calc(90px + 8vw)',
    height: 'calc(90px + 8vw)',
    maxWidth: '200px',
    maxHeight: '200px',
    margin: '0px auto',
    fontSize: '64px',
    [theme.breakpoints.down('xs')]: {
      width: '200px',
      height: '200px',
    }
  }
});
class Profile extends React.Component {
  loadImageFromS3 = async (key) => {
    if(key == null || key.length == 0) return null;
    Storage.get(key, { level: 'public'})
    .then(result => {
      this.setState({
        profileImageSource: result,
      });
    }).catch(e => console.log(e));
  }
  constructor(props) {
    super(props)
    this.state = {
      isEditing: false,
      user: {
        username: props.user.username,
        createdAt: props.user.createdAt,
        bio: props.user.bio === undefined ?
        '' : props.user.bio,
        userLocation: props.user.userLocation === undefined ?
        '' : props.user.userLocation,
        website: props.user.website === undefined ?
        '' : props.user.website,
        profileImageKey: props.user.profileImageKey === undefined ?
        '' : props.user.profileImageKey,
      },
      profileImageSource: null,
      profileImageUpdated: false,
    };
    this.loadImageFromS3(props.user.profileImageKey === undefined ?
    '' : props.user.profileImageKey);
  }
  handleEdit = () => {
    if(this.props.editable) {
      this.setState({
        isEditing: true
      });
    }
  }
  handleSave = () => {
    if(this.props.editable) {
      API.post("holler-api", "/update-user", {
        body: this.state.user
      });
      const { profileImageUpdated } = this.state;
      this.setState({
        isEditing: false,
        profileImageUpdated: false
      });
      if(profileImageUpdated) {
        this.props.history.go(0);
      }
    }
  }
  handleUserFieldChange = (event) => {
    let newUser = Object.assign({}, this.state.user);
    newUser[event.target.name] = event.target.value;
    this.setState({
      user: newUser
    })
  }
  renderAttribute = (attribute, label) => {
    return (
        this.state.isEditing == false ?
        <React.Fragment>
          <Typography variant="subtitle1" style={{marginTop: '10px'}}>
          {label + ":"}
          </Typography>
          <Typography variant="subtitle2">
            {
              this.state.user[attribute].length === 0 ?
              "None" : this.state.user[attribute]
            }
          </Typography>
        </React.Fragment>
        :
        <TextField
        style={{
          marginBottom: '15px'
        }}
        name={attribute}
        label={label}
        variant="outlined"
        size="small"
        value={this.state.user[attribute]}
        onChange={this.handleUserFieldChange}
        multiline
        fullWidth/>
    );
  }
  handleUpload = async (event) => {
    // Preserves the object's state in asynchronous functions
    event.persist()
    if(this.state.user.profileImageKey !== '') {
      await Storage.vault.remove(this.state.user.profileImageKey, { level: 'public'});
    }
    const file = event.target.files[0];
    if(!file) {
      alert("You must specify a file");
      return;
    }
    if(file.type.indexOf("image") === -1) {
      alert("You may only upload images");
      return;
    }
    const name = uuid.v1() + "." + file.name.split('.').pop();
    const stored = await Storage.vault.put(name, file, {
      level: 'public',
      contentType: file.type,
    });
    let user = {...this.state.user};
    user.profileImageKey = stored.key;
    await this.setState({
      user: user,
      profileImageUpdated: true
    });
    this.props.history.push("/");
    this.loadImageFromS3(stored.key);
    API.post("holler-api", "/update-user", {
      body: this.state.user
    });
  }
  render() {
    const { classes } = this.props;
    let attributeContainerStyle = {
      paddingBottom: this.state.isEditing ? '0px' : '20px'
    };
    const d = this.state.user.createdAt;
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    return (
      <Card raised={true} className={classes.card}>
        <IconButton
        disabled={this.props.editable === false || this.state.isEditing}
        color="primary"
        className={classes.edit}
        onClick={this.handleEdit}>
          <EditIcon />
        </IconButton>
        {
          this.state.profileImageSource === null ?
          <React.Fragment>
            <Avatar className={classes.avatar}>
            {this.state.user.username.charAt(0)}
            </Avatar>
          </React.Fragment>
          :
          <React.Fragment>
            <Avatar className={classes.avatar}
            src={this.state.profileImageSource}/>
          </React.Fragment>
        }
        <div className={classes.attributeContainer} style={attributeContainerStyle}>
        {
          this.state.isEditing === false ? null :
          <Button
          variant="contained"
          component="label"
          className={classes.uploadButton}
          disableElevation>
            Update Picture
            <input
            type="file"
            style={{ display: "none" }}
            onChange={event => this.handleUpload(event)}/>
          </Button>
        }
        <Typography variant="subtitle1">
        Username:
        </Typography>
        <Typography variant="subtitle2" className={classes.usernameText}>
          {this.state.user.username}
        </Typography>
        <Typography variant="subtitle1">
        Account Created On:
        </Typography>
        <Typography variant="subtitle2"
        className={classes.usernameText} style={{
          paddingBottom: this.state.isEditing ? '10px' : '5px',
        }}>
          {`${mo} ${da}, ${ye}`}
        </Typography>
        { this.renderAttribute("bio", "Bio") }
        { this.renderAttribute("userLocation", "User Location") }
        { this.renderAttribute("website", "Website") }
        </div>
        {
          this.state.isEditing == false ? null :
          <Button
          color="primary"
          variant="contained"
          onClick={this.handleSave}
          className={classes.saveButton}>
            Save
          </Button>
        }
      </Card>
    );
  }
}
Profile.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(withRouter(Profile));
