import { API, Auth } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import LoadingSplash from '../components/LoadingSplash';
import Post from '../components/Post';
import PropTypes from 'prop-types';
import React from 'react';
import { extractLinks } from '../libs/utility';
import Profile from '../components/Profile';
import PostCreator from '../components/PostCreator';
import ProfileSplash from '../components/ProfileSplash';
const styles = theme => ({
  root: {
    margin: theme.spacing(2),
    marginTop: '48px'
  },
  grid: {
    margin: '0 auto',
    maxWidth: '1100px'
  },
  gridItem: {
    paddingLeft: '0',
    paddingRight: '0'
  },
});
class Home extends React.Component {
  state = {
    profileLoading: true,
    currentUsername: null,
    currentUser: null,
    posts: null,
    users: []
  }
  componentDidMount = async () => {
    this.setState({
      profileLoading: true
    });
    API.get("holler-api", "/get-posts").then(result => {
      if(result.Count >= 0) {
        this.setState({
          posts: result.Items
        });
      }
    }).catch(err => console.log(err));
    let myUser = await Auth.currentAuthenticatedUser().catch(err => {});
    if(myUser !== undefined) {
      this.props.userLoggedIn();
      const result = await API.get("holler-api", `/get-user/${myUser.attributes.preferred_username}`);
      if(result.Count > 0) {
        this.setState({
          currentUser: {username: myUser.attributes.preferred_username, ...result.Items[0]},
          currentUsername: myUser.attributes.preferred_username,
        });
      }
      this.setState({
        profileLoading: false
      });
    } else {
      this.setState({
        profileLoading: false
      });
    }
  }
  getUser = (username) => {
    for(let i = 0; i < this.state.users.length; i += 1) {
      if(this.state.users[i].username == username) {
        return this.state.users[i];
      }
    }
    API.get("holler-api", `/get-user/${username}`)
    .then((result) => {
      if(result.Count > 0) {
        for(let i = 0; i < this.state.users.length; i += 1) {
          if(this.state.users[i].username === username) {
            return;
          }
        }
        const newUsers = [{username, ...result.Items[0]}, ...this.state.users];
        this.setState({
          users: newUsers
        });
      }
    });
    return null;
  }
  updatePost = async (postId, newContent) => {
    API.post("holler-api", "/update-post", {
      body: {
        postId: postId,
        content: newContent,
        username: this.state.currentUsername
      }
    }).catch((e) => console.log(e));
    let newPosts = [...this.state.posts];
    for(let i = 0; i < newPosts.length; i += 1) {
      if(newPosts[i].postId === postId) {
        newPosts[i].content = newContent;
      }
    }
    this.setState({
      posts: newPosts
    });
  }
  addPost = async (body) => {
    const newPost = await API.put("holler-api", "/create-post", {
      body: body
    });
    const posts = [newPost, ...this.state.posts];
    this.setState({
      posts: posts
    });
  }
  deletePost = async (post) => {
    if(this.state.currentUsername !== null) {
      API.del("holler-api", "/delete-post", {
        body: {
          username: this.state.currentUsername,
          postId: post.postId
        }
      });
      let newPosts = [];
      for(let i = 0; i < this.state.posts.length; i += 1) {
        if(this.state.posts[i].postId !== post.postId) {
          newPosts.push(this.state.posts[i]);
        }
      }
      this.setState({
        posts: newPosts
      });
    }
  }
  render() {
    const { classes } = this.props;
    let recentPostsMarkup = <LoadingSplash />
    if(this.state.posts) {
      let orderedPosts = [...this.state.posts];
      orderedPosts.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1);
      recentPostsMarkup = orderedPosts.map
      ((post, index) => {
        const [links, newContent] = extractLinks(post.content);
        post.content = newContent;
        for(let i = 0; i < links.length; i += 1) {
          post.imageURL = links[i][0];
          break;
        }
        const user = this.getUser(post.username);
        return (
          <Post
          userIsAuthenticated={this.state.currentUsername !== null}
          currentUser={this.state.currentUsername}
          userIsOwner={
          this.state.currentUsername !== null &&
          this.state.currentUsername === post.username}
          username={post.username}
          profileImageKey={user !== null &&
          'profileImageKey' in user ? user.profileImageKey : null}
          post={post}
          key={post.postId}
          deletePost={this.deletePost}
          updatePost={this.updatePost}/>
        );
      })
    }
    return (
      <div className={classes.root}>
      <Grid container direction="row-reverse" className={classes.grid} spacing={2}>
          <Grid item sm={4} xs={12}>
            {
              this.state.currentUser !== null ?
              <Profile user={this.state.currentUser} editable={true}/>
              :
              <ProfileSplash loading={this.state.profileLoading}/>
            }
          </Grid>
          <Grid item className={classes.gridItem} sm={8} xs={12}>
            {
              this.state.currentUsername === null ? null :
              <PostCreator username={this.state.currentUsername} addPost={this.addPost}/>
            }
            {recentPostsMarkup}
          </Grid>
        </Grid>
      </div>
    );
  }
}
Home.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(Home);
