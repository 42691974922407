import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import BlurOn from '@material-ui/icons/BlurOn';
const styles = theme => ({
  card: {
    position: 'sticky',
    top: '78px',
    margin: '24px',
    marginLeft: '0px',
    padding: '20px',
    [theme.breakpoints.down('xs')]: {
      position: 'static',
      marginLeft: '24px',
      marginBottom: '0px'
    }
  },
  blurOn: {
    display: 'block',
    color: '#3F51B5',
    fontSize: '64px',
    margin: '10px auto',
    marginBottom: '20px'
  }
});
class ProfileSplash extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Card raised={true} className={classes.card}>
        {
          this.props.loading ?
          <div style={{display: 'flex', height: '580px', alignItems: 'center', justifyContent: 'center'}}>
            <CircularProgress/>
          </div>
          :
          <div style={{lineHeight: 1.5}}>
            <div>
              <BlurOn className={classes.blurOn}/>
            </div>
            <Typography variant="subtitle1">
            <b>Holler</b> is a social media network project designed to be a hybrid between Facebook and Twitter.
            <br/><br/>Front-end: <a href="https://gitlab.com/abdelmalek.ihdene/holler-cli">[Gitlab Repo]</a>
            <br/>• NodeJS + NPM
            <br/>• Google's Material-UI
            <br/>• React + React Router DOM
            <br/>• AWS Amplify Javascript SDK
            <br/>• Hosted on Netlify
            <br/><br/>Back-end: <a href="https://gitlab.com/abdelmalek.ihdene/holler-api">[Gitlab Repo]</a>
            <br/>• AWS Lambda + Serverless
            <br/>• AWS API Gateway
            <br/>• AWS S3 Buckets
            <br/>• AWS Cognito Authentication
            <br/>• AWS DynamoDB Database
            <br/>• AWS CloudFormation
            </Typography>
          </div>
        }
      </Card>
    );
  }
};
ProfileSplash.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(ProfileSplash);
