export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  s3: {
    REGION: "us-east-1",
    BUCKET: "holler-api-dev-profileimagebucket-1x4czx5dcfo1a"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://5zf3xes13h.execute-api.us-east-1.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_298VyoMBP",
    APP_CLIENT_ID: "5kqt682h366va53n4uce6f734t",
    IDENTITY_POOL_ID: "us-east-1:df7e3b26-13fa-40cc-902d-a40c88e1f626"
  }
};
